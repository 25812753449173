<template>
  <div :class="['input-container', 'input-' + title]">
    <label :class="{ active: isFocused || this.isActive }" :for="this.id">{{ this.title }}</label>
    <input
      :autocomplete="this.autofill"
      @blur="(isFocused = false), checkURL()"
      @focus="isFocused = true, this.valid = true"
      :stageId="stageId"
      @input="updateValue"
      :id="this.id"
      :type="this.type"
      :listId="this.listId"
      :required="this.isRequired"
      :value="inputValue"
      v-model="inputValue"
      :class="{ hasError: !this.valid && this.type === 'url' && !isFocused }" />
    <span v-if="error !== null">{{ this.error }}</span>
    <button
      v-tippy="{ content: hint, theme: 'light-border', arrow: true }"
      id="hint-bubble"
      v-show="hint">
      <img id="hint-img" src="../../assets/question-mark.svg" />
    </button>
  </div>
</template>

<script>
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/material.css";
import "tippy.js/themes/translucent.css";
import { useModelStore } from "../../stores/model";
import { ref } from "vue";
import { isValid } from "i18n-iso-countries";
import Swal from "sweetalert2";

export default {
  emits: {
    change: null,
  },
  props: {
    id: Number,
    title: String,
    type: String,
    value: String,
    isRequired: { type: Boolean, default: false },
    image: {},
    hint: {
      type: String,
      default: null,
    },
    stageId: Number,
    listId: { type: Number, default: 1 },
    autofill: { type: String, default: 'on' }
  },
  data() {
    return {
      isFocused: false,
      valid: false,
      inputValue: ref(""),
      isActive: false,
      modelStore: useModelStore(),
      error: null,
    };
  },

  methods: {
    updateValue(event) {
      this.inputValue = ref(event.target.value.toString());
      this.modelStore.updateParamValue(this.stageId, this.listId, this.id, this.inputValue);
      this.$emit("change", this.inputValue.value);
    },

    fillParamValue() {
      var val = this.modelStore.getParamValue(this.stageId, this.listId, this.id);
      this.inputValue = ref(val);
      if (this.type === "url") this.checkURL();
    },
    checkURL() {
      var string = this.modelStore.getParamValue(this.stageId, this.listId, this.id);
      if (string === "") {
        this.valid = true
        return
      }
      this.valid = this.modelStore.checkURL(string)
      if(this.valid === false && this.type === "url")
      {
        Swal.fire({
              title: 'Bitte prüfen Sie Ihre Eingabe',
              text: 'Bei ' + this.title + ' Eingabeformat: "https://www..." oder "http://www..."',
              icon: 'warning',
              confirmButtonText: 'Bestätigen',
              confirmButtonColor: "#00a1e6"
            }).then(() => {
              return
            });
      }
    },
  },
  mounted() {
    this.fillParamValue();
  },

  watch: {
    value(newVal) {
      if (this.type === "url" && newVal !== "")
        this.valid = this.modelStore.checkURL(newVal)
      this.inputValue = newVal;
    },

    inputValue(val) {
      if (val == null || val == "") {
        this.isActive = false;
        return;
      } else {
        this.isActive = true;
      }
    },
  },
};
</script>

<style scoped>
* {
  background-color: #fff;
}

.input-container {
  position: relative;
  height: fit-content;
  margin: 1rem 0;
}

.input-container input {
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--light-gray);
  font-size: 1rem;
  padding: 0.5rem 0 0 0;
}

.input-container input:focus {
  outline: none;
  border-bottom: 3px solid var(--mdblue);
}

.input-container label {
  position: absolute;
  top: 0.4rem;
  left: 0;
  font-size: 1rem;
  color: var(--dark-gray);
  pointer-events: none;
  transition: all 0.4s ease;
}

.input-container label.active {
  top: -10px;
  font-size: 0.75rem;
}

.hint {
  position: absolute;
  font-size: 0.8rem;
  top: 0.9rem;
  right: 0;
  color: var(--dark-gray);
}

.hint-hide {
  display: none;
  transition: visibility 1s, opacity 0.5s linear;
}

#hint-bubble {
  position: absolute;
  top: 0.4rem;
  right: 0.1rem;
  border: none;
  text-decoration: none;
  cursor: pointer;
  height: fit-content;
  width: fit-content;
}

span {
  font-size: 12px;
  color: red;
}

#hint-img {
  height: 1rem;
}

.hasError {
  border-bottom: 2px solid rgb(255, 122, 122) !important;
}
</style>
