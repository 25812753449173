<template>
    <base-card v-if="!this.fatalError" class="base-card" title="Vielen Dank!" :hasTitle="true" :logo="imageFinish">
        <div class="text mt-2 mb-2">
            <p>Vielen Dank für Ihre Mitarbeit und die Erstellung des Firmen- und Produktprofils.</p>
            <p>Wer werden Veröffentlichung in den gewünschten Themen zur vereinbarten Laufzeit vornehmen.</p>
            <p>Sollten Sie Unterstützung benötigen, stehen wir Ihnen gerne zur Seite.
                MDESIGN Kundenbetreuung
                +49 234 30703-71
                marketing-services@mdesign.de</p>

            <div class="freigabe">
                <input-field class="input" v-for="input in this.inputFields" :id="input.id" :title="input.title"
                    :value="input.value" :type="input.type" :isRequired="input.isRequired" :stageId="6" :key="input.id"
                    :listId="1" :autofill="'off'">
                </input-field>
            </div>
            <div class="button-container">

                <one-button type="button" class="btn" :title="'Freigabe'" v-show="true"
                    v-on:click="postData()"></one-button>
                <!-- <one-button type="button" class="btn" :title="'testbtn'" v-show="true"
                    v-on:click="buttonTest()"></one-button> -->
            </div>
            <!-- <one-button type="button" class="btn" :title="'Get Data'" v-on:click="getData()"></one-button> -->
            <div>
                <p v-if="this.emailSent">Email wurde versendet.</p>
            </div>
        </div>
    </base-card>
    <h1 v-if="fatalError">Keine Daten gefunden!</h1>
</template>

<script>
import { useNavigationStore } from '../stores/navigation';
import BaseCard from '../components/BasicComponents/BaseCard.vue'
import imageFinish from '../assets/desktop/finish.svg'
import { ApiNames, httpGet, httpPost } from '../libs/httpHelper'
import { useModelStore } from '../stores/model'
import InputField from '../components/BasicComponents/InputField.vue';
import OneButton from "../components/BasicComponents/OneButton.vue";
import { useRouter } from 'vue-router'

const Swal = require('sweetalert2')
const stageNumber = 6
const router = useRouter()

export default {
    components: { BaseCard, OneButton, InputField },//Swal
    data() {
        return {
            company: "",
            imageFinish: imageFinish,
            navigationStore: useNavigationStore(),
            modelStore: useModelStore(),
            fatalError: false,
            emailSent: false,
            emailMsg: "",
            inputFields: [{
                id: 1,
                title: "Firma",
                type: "text",
                value: "",
                isRequired: true
            }, {
                id: 2,
                title: "Vorname",
                type: "text",
                value: "",
                isRequired: true
            }, {
                id: 3,
                title: "Nachname",
                type: "text",
                value: "",
                isRequired: true
            }, {
                id: 4,
                title: "E-Mail-Adresse",
                type: "text",
                value: "",
                isRequired: true
            }]
        };
    },
    beforeMount() {
        if (!this.modelStore.model) {
            this.fatalError = true
        }
        if (!this.modelStore.checkValidModel())
            this.fatalError = true

        var currStage = this.modelStore.getStage(stageNumber)
        if (currStage) {


            if (currStage.list.length > 0) {
                this.inputFields = currStage.list[0].params
            }
        }
        // this.modelStore.fillStageParams(stageNumber, 1, this.inputFields)
        var generalStage = this.modelStore.getStage(1)
        if (generalStage) {
            this.modelStore.updateParamValue(stageNumber, 1, 1, generalStage.list[0].params[0].value)
            this.inputFields[0].value = generalStage.list[0].params[0].value
        }
        this.modelStore.fillStageParams(stageNumber, 1, this.inputFields)
    },
    mounted() {
        this.navigationStore.step(6)
        this.navigationStore.items[6].active = true
    },
    methods:
    {
        async postData() {

            if (this.modelStore.validateEmail(this.modelStore.getParamValue(stageNumber, 1, 4)) !== null &&
                this.modelStore.getParamValue(stageNumber, 1, 1).trim() !== "" &&
                this.modelStore.getParamValue(stageNumber, 1, 2).trim() !== "" &&
                this.modelStore.getParamValue(stageNumber, 1, 3).trim() !== "") {

                var model = this.modelStore.model;
                await httpPost(ApiNames.model_save, model);
                this.emailMsg = await this.modelStore.sendEmail(this.modelStore.getParamValue(stageNumber, 1, 1).trim())
                if (this.emailMsg !== "" && this.emailMsg !== "err") {

                    this.emailSent = true

                    Swal.fire({
                        title: 'Email ist versendet.',
                        text: '',
                        icon: 'success',
                        confirmButtonText: 'Bestätigen',
                        confirmButtonColor: "#00a1e6"
                    }).then(() => {
                        this.$router.replace({ name: "welcome", query: { id: this.modelStore.model.id } })
                    });
                } else {
                    Swal.fire({
                        title: 'Fehler beim versenden der E-Mail',
                        text: '',
                        icon: 'error',
                        confirmButtonText: 'Bestätigen',
                        confirmButtonColor: "#00a1e6"
                    })
                }
                this.getData()

            }
            else {
                Swal.fire({
                    title: '',
                    text: 'Bitte geben Sie den Namen der Firma, Ihren Namen und E-Mail-Adresse an.',
                    icon: 'error',
                    confirmButtonText: 'Bestätigen',
                    confirmButtonColor: "#00a1e6"
                })
            }
        },

        buttonTest() {
            Swal.fire({
                title: 'Fehler beim versenden der E-Mail',
                text: 'Bitte prüfen Sie die Eingabefelder und versuchen es erneut. Bleib das Problem bestehen wenden Sie sich bitte an unseren Support.',
                icon: 'error',
                confirmButtonText: 'Bestätigen',
                confirmButtonColor: "#00a1e6"
            }).then(() => {
                this.$router.replace({ name: "welcome", query: { id: this.modelStore.model.id } })//,  params: { id: this.modelStore.model.id }
            });


        },

        async getData() {//async
            var response = await httpGet(ApiNames.model_get, this.modelStore.model.id, this.modelStore.token)
            var data = response.data;
            this.modelStore.model = data;
        },
    },

}
</script>

<style scoped>
.base-card {
    background-color: #fff;
    width: 60vw;
    height: fit-content;
}

.text {
    width: 100%;
}

p {
    margin: 1rem;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 100%;
    padding: 2rem;
}

.freigabe {

    display: flex;
    flex-direction: column;

    width: 100%;

}

.freigabe .input {
    width: 50%;
    margin: 0;
    margin-top: .8rem;
    margin-right: 1rem;
    margin-left: 1rem;
}
</style>